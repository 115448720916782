import { Form, FormikProps } from 'formik';
import { find, map, orderBy, filter } from 'lodash';
import * as React from 'react';
import { Clearfix, ControlLabel, FormGroup, HelpBlock, Panel, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Company, UserRole, AccessibleAgency, ActiveUser, Agency } from '../../types';
import { COLUMNS_TWO, COLUMNS_FOUR } from '../misc/FieldGroupColSizes';
import { FieldGroup } from '../misc/FieldGroup';
import { FieldGroupCheckbox } from '../misc/FieldGroupCheckbox';
export interface IUserForm {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    role_id: number;
    pass: string;
    confirm_pass: string;
    company_ids: number[];
    agency_ids: number[];
    role: {
        name: string;
        slug: string;
    };
    reset_password: boolean;
    api_key: string;
}

export const labels = {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    pass: 'Password',
    confirm_pass: 'Password Confirm',
    role_id: 'User role',
    company_ids: 'Company Assignment',
    reset_password: 'Change password at next login',
    api_key: 'User API Key'
};

interface ParentProps {
    activeUser: ActiveUser | null;
    roles: Record<number, UserRole>;
    companies: Company[];
    agencies: AccessibleAgency[];
    controls: (bag: FormikProps<IUserForm>) => React.ReactNode;
    editing: boolean;
    isNew: boolean;
    fetchUserData?: () => Promise<void | {
        agencies: Agency[],
        companies: Company[],
    }>
}

export default (props: ParentProps) => {
    const isCompanyRole = (bag: FormikProps<IUserForm>) => {
        const activeRole = find(props.roles, role => +role.id === +bag.values.role_id);
        return (
            activeRole &&
            !['super_admin', 'admin', 'agency_super_admin', 'agency_admin', 'chat'].includes(activeRole.slug)
        );
    };

    const showAgencies = (bag: FormikProps<IUserForm>) => {
        const activeRole = find(props.roles, role => +role.id === +bag.values.role_id);
        return activeRole && ['agency_super_admin', 'agency_admin'].includes(activeRole.slug) && props.agencies;
    };

    const handleCompaniesChange = (bag: FormikProps<IUserForm>) => (selected: Company[]) => {
        bag.setFieldValue('company_ids', map(selected, 'id'));
    };

    const handleAgenciesChange = (bag: FormikProps<IUserForm>) => (selected: AccessibleAgency[]) => {
        bag.setFieldValue('agency_ids', map(selected, 'id'));
    };

    const filteredRoles = () => {
        const roles = orderBy(props.roles, 'access_level');
        const activeUserRole = ((props.activeUser || {}).role || {}).slug || '';

        if (!['super_admin', 'agency_super_admin', 'agency_admin'].includes(activeUserRole)) {
            return roles.filter(role => !['agency_admin', 'agency_super_admin'].includes(role.slug));
        }

        return roles;
    };

    return (bag: FormikProps<IUserForm>) => {
        const { companies, agencies, fetchUserData } = props;
        const [activeCompanies, setActiveCompanies] = React.useState(companies);
        const [companiesData, setCompaniesData] = React.useState<Company[]>([]);
        const [activeAgencies, setActiveAgencies] = React.useState(agencies);

        React.useEffect(() => {
            if (!fetchUserData) return;

            fetchUserData().then(data => {
                if (data?.companies) {
                    setActiveCompanies(data.companies.filter(company => company.active));
                    setCompaniesData(data?.companies);
                }
                if (data?.agencies) {
                    setActiveAgencies(data.agencies.filter(agency => agency.active))
                }
            });

        }, []);

        const fieldProps = {
            ...bag,
            labels,
            disabled: !props.editing
        };
        return (
            <Form className="fixed-controls">
                <Panel bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <h4>User Details</h4>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="indent-outside">
                            <FieldGroup id="first_name" {...fieldProps} colSize={COLUMNS_FOUR} />
                            <FieldGroup id="last_name" {...fieldProps} colSize={COLUMNS_FOUR} />
                            <FieldGroup
                                id="email"
                                type="email"
                                {...fieldProps}
                                colSize={COLUMNS_FOUR}
                                autoComplete="username"
                            />
                            {
                                <FieldGroup id="role_id" componentClass="select" {...fieldProps} colSize={COLUMNS_FOUR}>
                                    {map(filteredRoles(), role => (
                                        <option
                                            value={`${role.id}`}
                                            key={role.id}
                                            disabled={
                                                props.isNew || props.activeUser?.id !== fieldProps.values.id
                                                    ? false
                                                    : role.slug === 'company_light'
                                            }
                                        >
                                            {role.name}
                                        </option>
                                    ))}
                                </FieldGroup>
                            }
                            <div className="user-form__holder">
                                {/*
                                    NOTE: If there is an activeCompany, we don't want to show this field, because
                                    if the user has been assigned to multiple companies, all but one of the `company_ids`
                                    will not find a match in the `companies` array.

                                    Likewise, the user being edited may have access to other companies, but a user with a
                                    company role should not be able to edit them.

                                    Also, when viewing this page at the company level (regardless of the activeUser's role)
                                    we will hide this, because the activeCompany is now determined by the URL.

                                    ==========

                                    TODO: We will also probably want to consider adding a "Users" navigation item at
                                    the company and agency levels. Right now, we are only display the top-level "Users"
                                    navigation item at the Admin/Super Admin level. Once you drill down into an agency
                                    or company, the "Users" navigation item is hidden.
                                 */}
                                {companiesData.length > 1 && isCompanyRole(bag) && (
                                    <FormGroup
                                        controlId="company_ids"
                                        className="col-md-6 col-md-12 user-block-details"
                                        validationState={bag.errors.company_ids ? 'error' : null}
                                    >
                                        <ControlLabel>Assigned Companies</ControlLabel>
                                        <Typeahead<Company>
                                            disabled={fieldProps.disabled}
                                            multiple
                                            id="companiesTypeahead"
                                            labelKey={company => {
                                                return `${company.name} ${company.state ? ' - ' + company.state : ''}`;
                                            }}
                                            options={
                                                activeCompanies.filter(comapny => !bag.values.company_ids.includes(comapny.id)
                                                )}
                                            selected={map(
                                                bag.values.company_ids,
                                                id => find(props.isNew ? activeCompanies : companiesData, c => c.id === id)!
                                            )}
                                            filterBy={(state, input) => {
                                                return `${state.name} ${state.state ? ' - ' + state.state : ''}`
                                                    .toLowerCase()
                                                    .includes(input.text.toLowerCase());
                                            }}
                                            renderMenuItemChildren={company => {
                                                return `${company.name} ${company.state ? ' - ' + company.state : ''}`;
                                            }}
                                            onChange={handleCompaniesChange(bag)}
                                            placeholder="Please Select a Company to Assign"
                                        />
                                        {bag.errors.company_ids && <HelpBlock>{bag.errors.company_ids}</HelpBlock>}
                                    </FormGroup>
                                )}
                            </div>
                            <div className="user-form__holder">
                                {showAgencies(bag) && (
                                    <FormGroup
                                        controlId="agency_ids"
                                        className="col-md-6 col-md-12 user-block-details"
                                        validationState={bag.errors.agency_ids ? 'error' : null}
                                    >
                                        <ControlLabel>Assigned Agencies</ControlLabel>
                                        <Typeahead<AccessibleAgency>
                                            disabled={fieldProps.disabled}
                                            multiple
                                            id="agenciesTypeahead"
                                            labelKey="name"
                                            options={activeAgencies}
                                            selected={filter(activeAgencies, a => bag.values.agency_ids?.indexOf(a.id) > -1)}
                                            onChange={handleAgenciesChange(bag)}
                                            placeholder="Please Select an Agency to Assign"
                                        />
                                        {bag.errors.agency_ids && <HelpBlock>{bag.errors.agency_ids}</HelpBlock>}
                                    </FormGroup>
                                )}
                            </div>
                            <Clearfix />
                            <FieldGroup
                                id="pass"
                                type="password"
                                {...fieldProps}
                                colSize={COLUMNS_TWO}
                                autoComplete="new-password"
                            />
                            <FieldGroup
                                id="confirm_pass"
                                type="password"
                                {...fieldProps}
                                colSize={COLUMNS_TWO}
                                autoComplete="new-password"
                            />
                            <FieldGroup
                                id="api_key"
                                {...fieldProps}
                                colSize={COLUMNS_TWO}
                            />
                            <Col className="col-lg-12">
                                <FieldGroupCheckbox
                                    id="reset_password"
                                    {...fieldProps}
                                    tooltip="If checked, user must change their password at their next login."
                                />
                            </Col>
                        </div>
                    </Panel.Body>
                </Panel>
                {props.controls(bag)}
            </Form>
        );
    };
};
